import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {AbstractBoE, BillOfEntry} from '../models/billOfEntries';
import {BillsOfEntry} from '../models/billsOfEntry';
import {ClearingFile} from '../models/clearingFile';
import {catchError, map} from 'rxjs/operators';
import {FileType} from '../models/enumerations';
import {SendTrackingEventRequest} from '../models/ForwardingFile';

@Injectable({
  providedIn: 'root'
})
export class BillOfEntryService {
  clearingFile: ClearingFile;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {

  }

  generateBOE(billsOfEntry: BillsOfEntry, clearingFile: ClearingFile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0]._links && billsOfEntry.boes[0]._links.self) {
      billsOfEntry.boes[0].clearingFile = clearingFile._links.self.href;
      return this.http.put(
        billsOfEntry.boes[0]._links.self.href,
        JSON.stringify(billsOfEntry.boes[0]),
        {headers}
      ).pipe(
        map((response) => response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : [])
      );
    } else {
      if (billsOfEntry && billsOfEntry.boes.length > 1 && billsOfEntry.boes[0].clearingFile) {
        billsOfEntry.boes[0].clearingFile = clearingFile._links.self.href;
      }
      return this.http.post(
        this.config.filesApiEndpoint + 'api/clearingFiles/' + clearingFile.id + '/billOfEntries/generate',
        {headers}
      ).pipe(
        map((response) => response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : [])
      );
    }
  }

  regenerateBOE(clearingFile: ClearingFile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.put(this.config.filesApiEndpoint + 'api/clearingFiles/' + clearingFile.id + '/billOfEntries/regenerate', {headers}
    ).pipe(
      map((response) => response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : [])
    );
  }

  findPreviousBillOfEntry(lrn: String, sequence: number, isManuallySubmitted: boolean): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/billOfEntries/search/findByLrnAndSequenceNumberAndMarkedAsSubmitted?lrn=`
      + lrn + '&sequenceNumber=' + sequence + '&isManuallySubmitted=' + isManuallySubmitted);
  }

  findParentClearingFile(billId, fileType): Observable<any> {
    if (fileType === 'imports') {
      return this.http.get(`${this.config.filesApiEndpoint}api/corrections/` + billId + '/parent');
    } else {
      return this.http.get(`${this.config.filesApiEndpoint}api/exportFileCorrections/` + billId + '/parent');
    }
  }

  findBillOfEntry(boe: AbstractBoE): Observable<any> {
    return this.http.get(boe._links.self.href.replace('\{\?projection\}', ''));
  }

  findBOEsWithVAT(importerCode: string, customsOfficeCode: string, fromDate: string, toDate: string, branchCode: string): Observable<any> {
    let params = `importerCode=${importerCode}&customsOfficeCode=${customsOfficeCode}&from=${fromDate}&to=${toDate}`;
    if (branchCode) {
      params += `&branchCode=${branchCode}`;
    }
    return this.http.get(`${this.config.filesApiEndpoint}api/importBillOfEntries/search/byImporterCodeCustomsOfficeAndDateRange?${params}`).pipe(
      map(response => response['_embedded'] ? response['_embedded'].importBillOfEntries : [])
    );
  }

  saveBillOfEntry(billOfEntry: BillOfEntry, clearingFile: ClearingFile): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    if (billOfEntry._links && billOfEntry._links.self) {
      billOfEntry.clearingFile = clearingFile._links.self.href;
      const url = billOfEntry._links.self.href.replace('\{\?projection\}', '');
      if (billOfEntry.cusdecs) {
        billOfEntry.cusdecs = billOfEntry.cusdecs.filter(cusdec => cusdec !== null);
      }
      return this.http.put(url, JSON.stringify(billOfEntry), {headers});
    }
  }

  loadFiles(id: String): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/document/` + id);
  }

  loadUploadedFile(billOfEntry: BillOfEntry): Observable<any> {
    return this.http
      .get(`${billOfEntry._links.files.href}`);
  }

  uploadFiles(files: File[], billOfEntry: BillOfEntry) {
    const data = new FormData();
    data.append('file', files[0]);
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
    return this.http.post(`${billOfEntry._links.files.href}`, data, {headers});
  }

  retrieveBillOfEntry(currentBillOfEntry): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = currentBillOfEntry._links.self.href.replace('\{\?projection\}', '');
    return this.http.get(url);
  }

  findBillOfEntryByClearingFile_id(id): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/importBillOfEntries/search/findByClearingFile_Id?id=` + id).pipe(
      map((response) => response['_embedded'].importBillOfEntries)
    );
  }

  findBillOfEntryByExportFile_id(id): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/exportBillOfEntries/search/findByExportFile_Id?id=` + id);
  }

  findAllEdiMessages(page = 0, size = 10, indicator, branchCode: string): Observable<any> {
    let endpoint = '?';
    if (branchCode) {
      endpoint = `/search/findByBranchCode?branchCode=${branchCode}&`;
    }

    if (indicator === 'imports') {
      return this.http.get(
        `${this.config.filesApiEndpoint}api/importBillOfEntries${endpoint}projection=onlyEdiMessages&page=${page}&size=${size}&sort=dateEdiSubmitted,desc`
      );
    } else if (indicator === 'exports') {
      return this.http.get(
        `${this.config.filesApiEndpoint}api/exportBillOfEntries${endpoint}projection=onlyEdiMessages&page=${page}&size=${size}&sort=dateEdiSubmitted,desc`
      );
    } else {
      return this.http.get(
        `${this.config.filesApiEndpoint}api/exBondBillOfEntries${endpoint}projection=onlyEdiMessages&page=${page}&size=${size}&sort=dateEdiSubmitted,desc`
      );
    }
  }

  findAllRcgEdiMessages(page = 0, size = 10, branchCode: string, mawbNo: string, filterRejections: boolean): Observable<any> {
      let endpoint = '?';
      if (branchCode) {
        if (filterRejections) {
          endpoint = `/search/findByMawbAndBranchCodeAndFromRcgToClearingAndRejected?branchCode=${branchCode}&mawbNo=${mawbNo}&`;
        } else {
          endpoint = `/search/findByMawbAndBranchCodeAndFromRcgToClearing?branchCode=${branchCode}&mawbNo=${mawbNo}&`;
        }
      } else {
        if (filterRejections) {
          endpoint = `/search/findBOEsFromRcgByMawbAndIsRejected?mawbNo=${mawbNo}&`;
        } else {
          endpoint = `/search/findBOEsFromRcgByMawb?mawbNo=${mawbNo}&`;
        }
      }

      return this.http.get(
        `${this.config.filesApiEndpoint}api/importBillOfEntries${endpoint}projection=onlyEdiMessages&page=${page}&size=${size}&sort=clearingFileNumber,desc`
      );
    }

  findAllRcgManifestRejections(mawbNo: string): Observable<any> {
    let endpoint = `/search/findBillsFromRcgByMawbAndIsRejected?mawbNo=${mawbNo}&`;
    return this.http.get(
      `${this.config.filesApiEndpoint}api/importBillOfEntries${endpoint}projection=onlyEdiMessages&sort=clearingFileNumber,desc`)
      .pipe(
        map(response => response['_embedded'].importBillOfEntries)
      );
  }

  findBoesByParameters(
    mawbNo: string,
    declarantName: string,
    foreignDeclarantName: string,
    containerNo: string,
    lrnNo: string,
    fileNo: string,
    referenceNo: string,
    mrnNo: string,
    caseNo: string,
    supplierInvNo: string,
    clearingFileId: string,
    fromDate: string,
    toDate: string,
    branch: string,
    fileType: FileType): Observable<any> {

    const params = this.buildParams({mawbNo, declarantName, foreignDeclarantName, containerNo, lrnNo, fileNo, referenceNo, mrnNo, caseNo, supplierInvNo,
      clearingFileId, fromDate, toDate, branch}); // will be an object when we add more to criteria
    if (fileType === 'imports') {
      return this.http.get(
        `${this.config.filesApiEndpoint}api/importBillOfEntries/search/filterBoesBy?projection=onlyEdiMessages&${params}`
      ).pipe(
        map((response) => response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : [])
      );
    } else if (fileType === 'exports') {
      return this.http.get(
        `${this.config.filesApiEndpoint}api/exportBillOfEntries/search/filterBoesBy?projection=onlyEdiMessages&${params}`
      ).pipe(
        map((response) => response['_embedded'] && response['_embedded'].exportBillOfEntries ? response['_embedded'].exportBillOfEntries : [])
      );
    } else {
      return this.http.get(
        `${this.config.filesApiEndpoint}api/exBondBillOfEntries/search/filterBoesBy?projection=onlyEdiMessages&${params}`
      ).pipe(
        map((response) => response['_embedded'] && response['_embedded'].exBondBillOfEntries ? response['_embedded'].exBondBillOfEntries : [])
      );
    }
  }

  markBoesAsSubmitted(clearingFileId: string, selectedBoes: BillOfEntry[]): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const ids = selectedBoes.map(el => el.id);
    return this.http.put(this.config.filesApiEndpoint + 'api/clearingFiles/' + clearingFileId + '/billOfEntries/submit', ids, {headers}
    ).pipe(
      map((response) => response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : [])
    );
  }

  buildParams(paramObj) {
    const params = [];
    for (const key in paramObj) {
      if (paramObj[key]) {
        params.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramObj[key]));
      }
    }
    return params.join('&');
  }

  findByCustomsProcedureCodeAndMrn(cpc: number, mrn: string): Observable<any> {
      if (mrn && mrn !== null && mrn.trim() !== '') {
        return this.http.get(
          `${this.config.filesApiEndpoint}api/importBillOfEntries/search/findByCustomsProcedureCodeAndMrn?customsProcedureCode=${cpc}&mrn=${mrn}`
        ).pipe(
          map((response) => response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : [])
        );
      } else {
        return this.http.get(
          `${this.config.filesApiEndpoint}api/importBillOfEntries/search/findByCustomsProcedureCode?customsProcedureCode=${cpc}`
        ).pipe(
          map((response) => response['_embedded'] && response['_embedded'].importBillOfEntries ? response['_embedded'].importBillOfEntries : [])
        );
      }
    }

  updateReceiptInfo(customBoes) {
    return this.http.post(`${this.config.filesApiEndpoint}api/importBillOfEntries/updateReceiptInfo`, customBoes);
  }

  sendTrackingEvent(request: SendTrackingEventRequest): Observable<boolean> {

    const url = `${this.config.filesApiEndpoint}api/sendTrackingEvent`;

    return this.http.post<boolean>(url, request).pipe(
        map(response => {
          return response;
        }),
        catchError((error) => {
          console.error('Error sending tracking event', error);
          return of(false);
        })
    );
  }
}

