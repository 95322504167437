<p-dialog
          [(visible)]="display"
          (onHide)="onClose()"
          [modal]="true"
          [responsive]="true"
          [width]="800"
          [minWidth]="400"
          [minY]="100"
          [maximizable]="false">
  <div class="ui-g">
      <div class="ui-g-12">
        <div class="ui-g ui-fluid">
          <p-panel header="Tracking Details" class="ui-g-12">
            <div class="ui-g form-group">

        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask
                                               [(ngModel)]="trackingDetails.submissionDate"
                                               id="submissionDate"
                                               mask="99/99/9999 99:99"
                                               slotChar="dd/mm/yyyy HH:mm"
                                               disabled
                                               >
                                  </p-inputMask>
                                  <label
                                    for="submissionDate">Submission To Customs</label>
                                </span>
                              </span>
                            </span>
        </div>

        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask
                                               [(ngModel)]="trackingDetails.releaseDate"
                                               id="releaseDate"
                                               mask="99/99/9999 99:99"
                                               slotChar="dd/mm/yyyy HH:mm"
                                               disabled
                                  >
                                  </p-inputMask>
                                  <label
                                    for="releaseDate">Released By Customs</label>
                                </span>
                              </span>
                            </span>
        </div>

        <div class="ui-g-12 ui-md-12">
          <div [ngClass]="isHouseBill == true ? 'ui-g-12 ui-md-12': 'ui-g-8 ui-md-8'">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask
                                               [(ngModel)]="trackingDetails.receivedAtORTIA"
                                               id="receivedAtORTIA"
                                               mask="99/99/9999 99:99"
                                               slotChar="dd/mm/yyyy HH:mm"
                                  >
                                  </p-inputMask>
                                  <label
                                    for="receivedAtORTIA">Received At Airport</label>
                                </span>
                              </span>
                            </span>
          </div>
          <div *ngIf="!isHouseBill" class="ui-g-4 ui-md-4">
            <button pButton type="button" label="Update House Bills" icon="fa fa-refresh"
                    (click)="onUpdateHAWBSArrivedATORTIA()"></button>
          </div>
        </div>



              <div class="ui-g-12 ui-md-12">
                <div [ngClass]="isHouseBill == true ? 'ui-g-12 ui-md-12': 'ui-g-8 ui-md-8'">
                  <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask
                                    [(ngModel)]="trackingDetails.receivedAtJNBOPS"
                                    id="receivedAtJNBOPS"
                                    mask="99/99/9999 99:99"
                                    slotChar="dd/mm/yyyy HH:mm"
                                  >
                                  </p-inputMask>
                                  <label
                                    for="receivedAtJNBOPS">Received At JNB OPS</label>
                                </span>
                              </span>
                            </span>
                </div>
                <div *ngIf="!isHouseBill" class="ui-g-4 ui-md-4">
                  <button pButton type="button" label="Update House Bills" icon="fa fa-refresh"
                          (click)="onUpdateHAWBsArrivedAtJNBOPSFields()"></button>
                </div>
              </div>

              <div *ngIf="!isHouseBill" class="ui-g-12 ui-md-12" >
                <div [ngClass]="isHouseBill == true ? 'ui-g-12 ui-md-12': 'ui-g-8 ui-md-8'">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="currentStatus"
                                         id="status"
                                         disabled
                                  >
                                  <label for="status">Current Status</label>
                                </span>
                              </span>
                            </span>
                </div>
                <div class="ui-g-4 ui-md-4">
                  <button pButton type="button" label="Submit To API" icon="fa fa-update"
                          [disabled]="onSendParcelUpdateDisabled()"
                          (click)="onSendParcelUpdate()"
                  ></button>
                </div>
              </div>

              <ng-container *ngIf="isHouseBill">

                <div class="ui-g-12 ui-md-12">
                  <div [ngClass]="isHouseBill == true ? 'ui-g-12 ui-md-12': 'ui-g-8 ui-md-8'">
                  <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask
                                    [(ngModel)]="trackingDetails.podDate"
                                    id="podDate"
                                    mask="99/99/9999 99:99"
                                    slotChar="dd/mm/yyyy HH:mm"
                                  >
                                  </p-inputMask>
                                  <label
                                    for="podDate">Proof Of Delivery (POD)</label>
                                </span>
                              </span>
                            </span>
                  </div>
                </div>

              <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <textarea [rows]="3" pInputTextarea autoResize="autoResize"
                                    [(ngModel)]="trackingDetails.comment"
                                    id="comment"
                                  >
                                  </textarea>
                                  <label
                                    for="comment">Comment / Exception</label>
                                </span>
                              </span>
                            </span>
              </div>

              <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                    [(ngModel)]="trackingDetails.hawbAndDetails"
                                    id="hawbAndDetails"
                                  >
                                  <label
                                    for="hawbAndDetails">HAWB & Details (If Not Manifested)</label>
                                </span>
                              </span>
                            </span>
              </div>

                <div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-12">
                    <span>Customs Clearance Checkpoints</span>
                  </div>
                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isCustomsReleased"
                                name="customsReleasedCheck"
                                label="Customs Released"
                                (onChange)="setStatusCommentTime($event, 'statusCustomsReleased')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusCustomsReleased" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusCustomsReleased" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusCustomsReleased}}</span>
                    </div>
                  </div>
                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isStoppedForInspection"
                                name="stoppedForInspectionCheck"
                                label="Stopped For Inspection"
                                (onChange)="setStatusCommentTime($event, 'statusStoppedForInspection')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusStoppedForInspection" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusStoppedForInspection" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusStoppedForInspection}}</span>
                    </div>
                  </div>

                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isConditionallyReleased"
                                name="conditionallyReleasedCheck"
                                label="Conditionally Released"
                                (onChange)="setStatusCommentTime($event, 'statusConditionallyReleased')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusConditionallyReleased" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusConditionallyReleased" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusConditionallyReleased}}</span>
                    </div>
                  </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isShipmentDetainedForOGA"
                                name="shipmentDetainedForOGACheck"
                                label="Shipment Detained for OGA/MCC"
                                (onChange)="setStatusCommentTime($event, 'statusShipmentDetainedForOGA')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusShipmentDetainedForOGA" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusShipmentDetainedForOGA" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusShipmentDetainedForOGA}}</span>
                    </div>
                  </div>
                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isSupportingDocumentsRequired"
                                name="supportingDocumentsRequiredCheck"
                                label="Supporting Documents Required"
                                (onChange)="setStatusCommentTime($event, 'statusSupportingDocumentsRequired')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusSupportingDocumentsRequired " class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusSupportingDocumentsRequired " class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusSupportingDocumentsRequired }}</span>
                    </div>
                  </div>

                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isShipmentReferredForChecking"
                                name="shipmentReferredForCheckingCheck"
                                label="Shipment Referred for Checking"
                                (onChange)="setStatusCommentTime($event, 'statusShipmentReferredForChecking')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusShipmentReferredForChecking" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusShipmentReferredForChecking" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusShipmentReferredForChecking}}</span>
                    </div>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isOGASupportingDocuments"
                                name="ogaSupportingDocumentsCheck"
                                label="OGA/MCC Supporting Documents"
                                (onChange)="setStatusCommentTime($event, 'statusOGASupportingDocuments')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusOGASupportingDocuments " class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusOGASupportingDocuments " class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusOGASupportingDocuments }}</span>
                    </div>
                  </div>
                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isOGAReleased"
                                name="ogaReleasedCheck"
                                label="OGA/MCC Released"
                                (onChange)="setStatusCommentTime($event, 'statusOGAReleased')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusOGAReleased" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusOGAReleased" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusOGAReleased}}</span>
                    </div>
                  </div>

                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isExamScheduleFor"
                                name="examScheduleForCheck"
                                label="Exam Scheduled for"
                                (onChange)="setStatusCommentTime($event, 'statusExamScheduleFor')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusExamScheduleFor " class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusExamScheduleFor " class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusExamScheduleFor }}</span>
                    </div>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isExamComplete"
                                name="examCompleteCheck"
                                label="Exam Complete – Awaiting Report"
                                (onChange)="setStatusCommentTime($event, 'statusExamComplete')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusExamComplete" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusExamComplete" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusExamComplete}}</span>
                    </div>
                  </div>

                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isReportReceived"
                                name="reportReceivedCheck"
                                label="Report Received"
                                (onChange)="setStatusCommentTime($event, 'statusReportReceived')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusReportReceived " class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusReportReceived " class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusReportReceived }}</span>
                    </div>
                  </div>

                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isSupportingDocumentsReceived"
                                name="supportingDocumentsReceivedCheck"
                                label="Supporting Documents Received"
                                (onChange)="setStatusCommentTime($event, 'statusSupportingDocumentsReceived')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusSupportingDocumentsReceived" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusSupportingDocumentsReceived" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusSupportingDocumentsReceived}}</span>
                    </div>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isOutcomeOfInspection"
                                name="outcomeOfInspectionCheck"
                                label="Outcome of Inspection"
                                (onChange)="setStatusCommentTime($event, 'statusOutcomeOfInspection')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusOutcomeOfInspection " class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusOutcomeOfInspection " class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusOutcomeOfInspection }}</span>
                    </div>
                  </div>

                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isProvisionPaymentOrVOClodged"
                                name="provisionPaymentOrVOClodgedCheck"
                                label="Provisional Payment / VOC Lodged"
                                (onChange)="setStatusCommentTime($event, 'statusProvisionPaymentOrVOClodged')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusProvisionPaymentOrVOClodged" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusProvisionPaymentOrVOClodged" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusProvisionPaymentOrVOClodged}}</span>
                    </div>
                  </div>
                  <div class="ui-g-1 ui-md-1">
                    <p-checkbox binary="true"
                                [(ngModel)]="isPickedUpByCustomsClearanceCompany"
                                name="PickedUpByCustomsClearanceCompany"
                                label="Picked Up By Customs Clearance Company"
                                (onChange)="setStatusCommentTime($event, 'statusPickedUpByCustomsClearanceCompany')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusPickedUpByCustomsClearanceCompany " class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusPickedUpByCustomsClearanceCompany " class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusPickedUpByCustomsClearanceCompany }}</span>
                    </div>
                  </div>
                </div>
                    <div class="ui-g-12 ui-md-12">
                        <div class="ui-g-1 ui-md-1">
                            <p-checkbox binary="true"
                                        [(ngModel)]="isImportCustomsHandover"
                                        name="ImportCustomsHandover"
                                        label="Import Customs Handover"
                                        (onChange)="setStatusCommentTime($event, 'statusImportCustomsHandover')"></p-checkbox>
                        </div>
                        <div *ngIf="!trackingDetails.statusImportCustomsHandover" class="ui-g-3">
                        </div>
                        <div *ngIf="trackingDetails.statusImportCustomsHandover" class="ui-g-3">
                            <div class="ui-inputgroup">
                                <span class="ui-inputgroup-addon">{{trackingDetails.statusImportCustomsHandover}}</span>
                            </div>
                        </div>
                        <div class="ui-g-1 ui-md-1">
                            <p-checkbox binary="true"
                                        [(ngModel)]="isCarrierPickedUp"
                                        name="CarrierPickedUp"
                                        label="Carrier Picked Up"
                                        (onChange)="setStatusCommentTime($event, 'statusCarrierPickedUp')"></p-checkbox>
                        </div>
                        <div *ngIf="!trackingDetails.statusCarrierPickedUp " class="ui-g-3">
                        </div>
                        <div *ngIf="trackingDetails.statusCarrierPickedUp " class="ui-g-3">
                            <div class="ui-inputgroup">
                                <span class="ui-inputgroup-addon">{{trackingDetails.statusCarrierPickedUp }}</span>
                            </div>
                        </div>
                      <div class="ui-g-1 ui-md-1">
                        <p-checkbox binary="true"
                                    [(ngModel)]="isTaxDutyCollection"
                                    name="TaxDutyCollection"
                                    label="Tax Duty Collection"
                                    (onChange)="setStatusCommentTime($event, 'statusTaxDutyCollection')"></p-checkbox>
                      </div>
                      <div *ngIf="!trackingDetails.statusTaxDutyCollection" class="ui-g-3">
                      </div>
                      <div *ngIf="trackingDetails.statusTaxDutyCollection " class="ui-g-3">
                        <div class="ui-inputgroup">
                          <span class="ui-inputgroup-addon">{{trackingDetails.statusTaxDutyCollection }}</span>
                        </div>
                      </div>
                    </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-g-3 ui-md-3">
                    <p-checkbox binary="true"
                                [(ngModel)]="isGeneralQueryChecked"
                                name="generalQueryCheck"
                                label="General Query"
                                (onChange)="setStatusCommentTime($event, 'statusGeneralQueryTimestamp')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusGeneralQueryTimestamp" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusGeneralQueryTimestamp" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusGeneralQueryTimestamp}}</span>
                    </div>
                  </div>
                  <div *ngIf="trackingDetails.statusGeneralQueryTimestamp" class="ui-g-6">
                  <span class="md-inputfield">
                    <span class="ui-float-label">
                      <span class="md-inputfield">
                        <textarea [rows]="3" pInputTextarea autoResize="autoResize"
                                  [(ngModel)]="trackingDetails.generalQuery"
                                  id="general-query-label">
                        </textarea>
                        <label
                          for="general-query-label">General Query</label>
                      </span>
                    </span>
                  </span>
                  </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </p-panel>
          <div *ngIf="displayTrackingProgressBar" class="ui-g-12">
            <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
          </div>
      </div>
      </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="fa fa-close" (click)="onClose();"
            label="Cancel"></button>
    <button type="button" pButton icon="fa fa-check" (click)="onSave();"
            label="Save"></button>
  </p-footer>
</p-dialog>
