import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PaymentsReportService} from '../services/payments-report.service';
import {NotificationService} from '../services/notification.service';

@Component({
  selector: 'digi-rcg-payments',
  templateUrl: './rcg-payments.component.html',
  styles: [`.ui-autocomplete-token {
      width: 90px !important;
  }
  .color-white input{
      color: white !important;
  }
  .color-white ::-webkit-input-placeholder {
      color: white;
  }

  .color-white :-ms-input-placeholder {
      color: white;
  }

  .color-white ::placeholder {
      color: white;
  }
  .page {
      border: 9px solid transparent !important;
      background-clip: content-box !important;
      -o-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
      border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;
  }
  `],
  encapsulation: ViewEncapsulation.None
})
export class RcgPaymentsComponent implements OnInit {
  mawbNo: string;
  fromDate: string;
  toDate: string;
  display = false;
  pdfSrc: string;
  disableButton: boolean;

  constructor(
    private paymentsService: PaymentsReportService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
  }

  generate() {
    this.disableButton = true;
    this.paymentsService.generateRcgPaymentsReport(this.mawbNo, this.fromDate, this.toDate).subscribe(data => {
        this.pdfSrc = data._embedded.supportingDocuments[0]._links.self.href + `?date=${Date.now()}`;
      },
      error => {
        this.notificationService.errorNotify(error.error.message, error.error.errors);
        this.pdfSrc = null;
        this.disableButton = false;
      });
  }

  openLink() {
    window.open(this.pdfSrc, '_blank');
  }
}
